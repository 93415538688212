/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; /* Keep Roboto for body text */
  background-color: #4CAF50; /* Vibrant green background */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* App Styles */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000000; /* Black for text */
  perspective: 9000px; 
}


/* Apply 3D transformation to the header and section containers */
.App-header, .about-section, .contract-section {
  transform: rotateY(20deg) translateZ(50px); /* Tilt panes slightly on the Y axis and move them out on the Z axis */
  transform-origin: center left; /* Pivot from the center left */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

/* Apply hover effect */
.App-header:hover, .about-section:hover, .contract-section:hover {
  transform: rotateY(0deg) translateZ(0px); /* Flatten and push back to original position on hover */
}

.top-image {
  max-width: 30%;
  height: auto;
  display: block;
  margin: 2rem auto;
}

.App-header {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  margin: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #000000;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align the items */
  justify-content: center; /* Center content vertically */
}

/* Header text styles */
.App-header h1, .App-header h2, .App-header h3 {
  margin: 0.5rem 0; /* Adjust space above and below */
}

.App-header h1 {
  font-size: 3rem;
  color: #58a6ff;
  font-family: 'Permanent Marker', cursive;
}

.App-header h2 {
  color: #ff7b72;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.App-header h3 {
  color: #000000;
  font-family: 'Roboto', sans-serif;
}

/* Content Sections with Glass Effect */
.green-container {
  padding: 20px;
  margin: 20px 0;
}

.about-section, .contract-section {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white for glass effect */
  backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the sections */
  max-width: 80%; /* Maximum width */
  color: #000000; /* Black for text */
}

.about-section h3, .contract-section h3 {
  font-family: 'Permanent Marker', cursive; /* Fun and bold for headings */
  font-size: 1.5rem; /* Slightly larger than normal text */
}

/* Text inside the about and contract sections */
.about-section p, .contract-section p {
  font-family: 'Roboto', sans-serif; /* Modern and readable font for body text */
  color: #000000; /* Black for text */
  font-weight: 400; /* Regular font weight for body text */
  margin-bottom: 1rem; /* Space between paragraphs */
}

/* Socials Section */
.socials-section {
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Social Icons */
.telegram-link img, .x-link img {
  width: 50px; /* Fixed size for social icons */
  height: auto; /* Maintain aspect ratio */
}

.telegram-link, .x-link {
  color: #58a6ff; /* Bright blue for social icons */
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.telegram-link:hover, .x-link:hover {
  transform: scale(1.1); /* Interactive hover effect */
}

/* Styling for Contract Info */
.contract-info {
  position: relative; /* Added to control the absolute positioning of children */
  display: inline-block; /* This will make the width fit its content */
  padding: 10px; /* Add padding as needed */
}

.copy-button {
  background-color: #1B5E20; /* Deep green for the button to stand out */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block; /* This will allow the button to sit inline with the text */
  vertical-align: middle; /* Align the button vertically with adjacent elements */
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #66BB6A; /* Lighter green on hover */
}

.copy-icon {
  width: 20px; /* Adjust as needed */
  height: 20px;
}

/* Copy Message Styling */
.copy-message {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 92%;
  top: 34%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  background-color: #21262d;
  color: white;
  margin-left: 10px;
}


.copy-message.visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.glitch span {
  display: inline-block;
  cursor: pointer;
}

.trade-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.trade-options-container button {
  background-color: #58a6ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.trade-options-container button:hover {
  background-color: #66BB6A;
}

.trade-heal-container h3 {
  cursor: url('iconDog.png'), auto;
  /* Other existing styles... */
}

.trade-iframe {
  width: 100%; /* Full width of the container */
  height: 500px; /* Set a fixed height, adjust as needed */
  border: none; /* Remove default border */
  margin-top: 20px; /* Space above the iframe */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
  border-radius: 10px; /* Optional: Rounds the corners */
}

.image-container {
  display: flex; /* Aligns the images horizontally */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows images to wrap on smaller screens */
}

.main-logo {
  max-width: 30%;
  height: auto;
  margin: 2rem; /* Adjust margin to ensure proper spacing */
}

.dino-doge-image {
  max-width: 100%; /* Ensures the image is not wider than its container */
  height: auto; /* Maintains the aspect ratio */
  margin-top: 20px; /* Adds some space above the image */
  cursor: pointer; /* Indicates the image is clickable */
}

.side-image {
  max-width: 15%; /* Adjust the size as needed */
  height: auto;
  margin: 1rem; /* Spacing around the images */
}

/* Text container styles */
.text-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align the items */
  justify-content: center; /* Center content vertically */
  margin-top: 1rem; /* Space above the text container */
}


/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .App-header, .about-section, .contract-section {
    transform: rotateY(0deg) translateZ(0px); /* Remove 3D effect for smaller screens */
  }

  .App-header h1 {
    font-size: 2.5rem;
  }

  .about-section, .contract-section {
    margin: 10px;
    max-width: 90%;
  }

  .green-container {
    margin: 10px 0;
  }

  .trade-iframe {
    height: 300px; /* Adjust height for smaller screens */
  }

  .side-image, .center-image {
    max-width: 20%; /* Adjust image size for small screens */
    margin: 0.5rem; /* Reduce margin for images */
  }

  .text-container {
    margin-top: 0; /* Reduce space if needed */
  }

  .image-container {
    justify-content: space-around; /* Spread out images evenly */
    margin-top: 20px; /* Add some space above the image container */
  }

    /* Adjust layout for trade options on small screens */
    .trade-options-container {
      width: 100%; /* Full width */
      padding: 10px; /* Add padding */
    }
  
    .trade-iframe {
      height: 300px; /* Adjust height for smaller screens */
    }
  
    .trade-heal-container {
      margin-top: 10px; /* Adjust spacing */
    }
  
    .green-container, .about-section, .contract-section {
      margin: 0; /* Adjust margin */
      max-width: 100%; /* Full width */
      padding: 15px; /* Adjust padding */
    }
  
    .socials-section {
      flex-direction: column; /* Stack social icons vertically */
    }


  
}
